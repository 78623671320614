
// 订单来源
export const columns_source_simple = [
	{
		title: "总订单数",
		dataIndex: "0",
		align: "center",
	},{
		title: "小程序门店",
		dataIndex: "2",
		align: "center",
		isTechComp: 1,
	},{
		title: "小程序快递",
		dataIndex: "1",
		align: "center",
		isTechComp: 1,
	},
	{
		title: "PC官网",
		dataIndex: "3",
		align: "center",
		isTechComp: 1,
	},
	{
		title: "手机官网",
		dataIndex: "4",
		align: "center",
		isTechComp: 1,
	},
	{
		title: "客服下单",
		dataIndex: "10",
		align: "center",
	},
	// {
	// 	title: "客服后台导入",
	// 	dataIndex: "11",
	// 	align: "center",
	// },
	{
		title: "网点自建单",
		dataIndex: "15",
		align: "center",
		isTechComp: 1,
	},
	// {
	// 	title: "第三方平台",
	// 	dataIndex: "third_plat",
	// 	align: "center",
	// 	// isTechComp: 2,
	// },
	{
		title: "收银下单",
		dataIndex: "16",
		align: "center",
		isTechComp: 1,
	},
	{
		title: "天猫",
		dataIndex: "23",
		align: "center",
		isTechComp: 2,
	},
	{
		title: "京东",
		dataIndex: "22",
		align: "center",
		isTechComp: 2,
	},
	{
		title: "抖音来客",
		dataIndex: "27",
		align: "center",
		isTechComp: 1,
	},
	{
		title: "饿了么",
		dataIndex: "21",
		align: "center",
		isTechComp: 1,
	},
	{
		title: "美团外卖",
		dataIndex: "20",
		align: "center",
		isTechComp: 1,
	},
	{
		title: "美团团购",
		dataIndex: "28",
		align: "center",
		isTechComp: 1,
	},
]


// 订单支付方式（财务账户）
export const columns_pay_simple = [
	{
		title: "总金额",
		dataIndex: "0",
		align: "center",
	},{
		title: "钱包",
		dataIndex: "1",
		align: "center",
		isTechComp: 1,
	},
	// {
	// 	title: "礼品卡支付",
	// 	dataIndex: "2",
	// 	align: "center",
	// },
	{
		title: "实体卡",
		dataIndex: "3",
		align: "center",
		isTechComp: 1,
	},
	{
		title: "微信",
		dataIndex: "10",
		align: "center",
	},
	{
		title: "支付宝",
		dataIndex: "11",
		align: "center",
	},
	{
		title: "货到付款",
		dataIndex: "30",
		align: "center",
		isTechComp: 1,
	},
	{
		title: "其它",
		dataIndex: "40",
		align: "center",
	},
	// {
	// 	title: "挂账",
	// 	dataIndex: "50",
	// 	align: "center",
	// 	isTechComp: 2,
	// },
	// {
	// 	title: "挂账-京东自营",
	// 	dataIndex: "50_amount_data",
	// 	align: "center",
	// 	slots: {
	// 		customRender: "50_amount_data",
	// 	},
	// 	slotsType: "format",
	// 	slotsFunc: (val) => {return val[10]+" 元"},
	// },
	{
		title: "挂账-天猫",
		dataIndex: "50_amount_data_13",
		align: "center",
		isTechComp: 2,
	},
	{
		title: "挂账-京东",
		dataIndex: "50_amount_data_11",
		align: "center",
		isTechComp: 2,
	},
	{
		title: "挂账-抖音来客",
		dataIndex: "50_amount_data_17",
		align: "center",
		isTechComp: 1,
	},
	{
		title: "挂账-饿了么",
		dataIndex: "50_amount_data_15",
		align: "center",
		isTechComp: 1,
	},
	{
		title: "挂账-美团外卖",
		dataIndex: "50_amount_data_12",
		align: "center",
		isTechComp: 1,
	},
	{
		title: "挂账-美团团购",
		dataIndex: "50_amount_data_18",
		align: "center",
		isTechComp: 1,
	},
	{
		title: "挂账-收银软件",
		dataIndex: "50_amount_data_19",
		align: "center",
		isTechComp: 1,
	},
]


export default {
  // columns_source,
  columns_source_simple,
  // columns_pay,
  columns_pay_simple
}
